import React from 'react'
import iconPlusWhite from '../../assets/icons/iconPlusWhite.svg'

function ExpandIcon() {
  return (
    <div className="expandIcon">
      <img src={iconPlusWhite} alt="expand icon" />
    </div>
  )
}

export default ExpandIcon
