import React, { useEffect, useState } from 'react'
import Layout from 'components/Layout'
import AppStateProvider from './../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/heroImage.svg'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandIcon from '../components/ExpandIcon'
import CollapseIcon from '../components/CollapseIcon'
import { Container } from 'components/PageContainer'
import './styles/faqs.scss'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { QuestionAnswer } from '@material-ui/icons'
import { JsonLd } from 'gatsby-plugin-next-seo'

function RootIndex() {
  const [faqs, setFaqs] = useState(null)
  const [loading, setLoading] = useState(true)

  const [faqList, setFaqList] = useState(null)
  

  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  useEffect(() => {
    fetch('https://api.swiftdoc.com/api/faq?size=100')
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setFaqs(data)
        console.log(data)
        const fl = data.map(( faq ) => {
          return {
            '@type': "Question",
            name: faq.question,
            acceptedAnswer: {
              "@type": "Answer", 
              text: "<p>" + faq.answer + "</p>"
            }
          }
        })
        console.log(fl)
        setFaqList(fl)
      })
      .catch((error) => {
        console.error('Error fetching FAQs:  ', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [loading])

  return (
    <AppStateProvider>
      <Layout 
      title="Telehealth FAQ | SwiftDoc"
      description = "Quetions and Answers to common telehealth, online prescriptions and more"
      >
        {faqList && 
      <JsonLd
            json={{
              '@context': 'http://www.schema.org',
              '@type': 'FAQPage',
              mainEntity: faqList
            }}
          />
        }

        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="For all your SwiftDoc questions we have the answers"
            subTitle="If you don’t see the answer to your query, please don’t hesitate to Contact Us"
            pageName="FAQS"
          />
        </div>
        <div className="bgWhiteFaqs">
          <Container>
            <div className="badgesFlexbox">
              {loading === true ? (
                <div className="lds-dual-ring" />
              ) : (
                faqs &&
                faqs.map((faq, index) => (
                  <Accordion
                    key={index}
                    square={true}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index}`}
                      id={`panel${index}`}
                      expandIcon={
                        expanded === `panel${index}` ? (
                          <CollapseIcon />
                        ) : (
                          <ExpandIcon />
                        )
                      }
                    >
                      {faq.question}
                    </AccordionSummary>
                    <AccordionDetails>{parse(faq.answer)}</AccordionDetails>
                  </Accordion>
                ))
              )}
            </div>
          </Container>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
