import React from 'react'
import iconMinusWhite from '../../assets/icons/iconMinusWhite.svg'

function CollapseIcon() {
  return (
    <div className="expandIcon">
      <img src={iconMinusWhite} alt="expand icon" />
    </div>
  )
}

export default CollapseIcon
